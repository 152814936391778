<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>每页条数</label>
          <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          <b-button variant="primary" :to="{ name: 'article_category-create' }"> 添加 </b-button>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="搜索文章类型..." width="200px" />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      fixed
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      bordered
      style="vertical-align: middle !important; text-align: center"
    >
      <!--   状态   -->
      <template #cell(status)="data">
        <b-badge v-if="data.value == '正常'" variant="success"> 正常 </b-badge>
        <b-badge v-else variant="danger"> 禁用 </b-badge>
      </template>

      <template #cell(actions)="data">
        <!--    编辑    -->
        <b-button variant="primary" size="sm" @click.prevent="setArtcDetail(data.item)">编辑 </b-button>
        <!--    删除    -->
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <b-button variant="danger" size="sm">删除</b-button>
          </template>
          <b-dropdown-item>
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span @click="confirmDelete(data.item.id)">确认删除</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">显示 {{ dataMeta.from }} 到 {{ dataMeta.to }} 条 {{ dataMeta.of }} 数据</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted, ref, getCurrentInstance } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useInvoicesList from './ArticleClassList'

import ArticleClassManagerModule from './ArticleClassManagerModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    vSelect,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'artc-module'
    const popoverShow = ref(false)
    const { proxy } = getCurrentInstance()
    //
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ArticleClassManagerModule)
    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })

    const setArtcDetail = data => {
      const ddat = JSON.parse(JSON.stringify(data))
      ddat.status = ddat.status == '正常' ? 1 : 0
      store.commit(`${INVOICE_APP_STORE_MODULE_NAME}/setArtcDetail`, ddat)
      setTimeout(() => {
        router.push({
          path: '/general/menu/article_category_list/edit',
        })
      }, 500)
    }

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,
      isLoading,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      confirmDelete,
    } = useInvoicesList(proxy)
    return {
      isLoading,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      popoverShow,
      setArtcDetail,
      confirmDelete,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.diy_center_td_table {
  ::v-deep .center_td {
    td {
      text-align: center !important;
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
